h1 {
  @include media-breakpoint-down(md) {
    font-size: 32px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}

.disabled {
  pointer-events: none;
}

.ratio {
  position: relative;
  width: 100%;
  > * {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  > img {
    object-fit: cover;
  }
}

// .ratio-16x9 {
//   padding-top: 56.25%;
// }

.brand-hover {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    background: linear-gradient(
      to left bottom,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.1) 50%
    );
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &::after {
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }
  }
}
.dropdownWrapper {
  background-color: $footer-copyright-bg;
  border-radius: 0;
  width: 100%;
  border: 2px solid rgb(85, 85, 85);
  border-top: none;
  margin-top: 2px !important;
  left: -2px;
  box-sizing: content-box;
  .dropdownItem {
    color: $softGrey2;
    &:hover {
      color: $darkCyan;
    }
  }
  .list {
    overflow: scroll;
    max-height: 20rem;
    padding: 0;
    margin: 0;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #000;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.3);
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    & > a {
      color: rgba(255, 255, 255, 0.3);
    }
  }
  .inputSearch {
    background-color: $footer-copyright-bg;
    color: rgba(255, 255, 255, 0.3);
    border-radius: 0;
    width: 100%;
    max-width: calc(100% - 2rem);
    border-color: rgba(255, 255, 255, 0.3);
    &:focus {
      background-color: $footer-copyright-bg;
      color: #ffffff;
      border-color: #ffffff;
    }
  }
}
.dropdownWidget {
  .categories {
    color: $softGrey2;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background-color: $footer-copyright-bg;
    font-family: inherit;
    font-size: 14px;
    line-height: inherit;
    padding: 11px 20px 12px 20px;
    height: auto;
    width: 100%;
    text-align: left;
    border-radius: 3px;
    cursor: pointer;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
      background-color 0.3s ease-in-out, width 0.3s ease-in-out;
    -webkit-transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
      background-color 0.3s ease-in-out, width 0.3s ease-in-out;

    &:hover,
    &:focus {
      color: #ffffff;
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
}
.drawer {
  padding-bottom: 1rem;
  border-bottom: $newsItemBorder;
  margin-bottom: 2rem;
  @include media-breakpoint-down(sm) {
    padding-bottom: 0;
    margin-bottom: 1rem;
  }
}

.global-single-content {
  h1 {
    font-weight: 700;
    font-size: 36px;
    @include media-breakpoint-down(md) {
      font-size: 28px;
    }
  }
}

.static-content {
  a {
    color: $linksColor;
  }
}

p {
  @include media-breakpoint-down(xs) {
    font-size: 0.8rem;
  }
}

// Layout switcher
.switcher {
  border: none;
  padding: 5px 10px;
  color: $softGrey;
  transition: all 0.2s ease-in-out;
  &:hover,
  &.active {
    color: white;
    background-color: $softDark3;
    transition: all 0.2s ease-in-out;
  }
  &:focus {
    border: none;
    outline: none;
  }
}

.grid-layout {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(320px, max-content));
  gap: 16px;
  margin-bottom: 16px;
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(auto-fill, minmax(260px, max-content));
  }
}

.viewAll {
  background-color: $red;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #fff;
  font-size: $font-size-16;
  text-align: center;
  margin: 0;
  cursor: pointer;
  font-family: $font-family-avnir-bold;
  transition: background-color ease 0.3s;
  border: none;
  border-radius: 4px !important;
  padding: $font-size-10 $font-size-20;

  &:hover {
    background-color: #1e2e4a;
    text-decoration: underline;
  }
}

.loadingEffect {
  animation: loadingEffect 1.5s ease-in-out infinite reverse both running;
}

@keyframes loadingEffect {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.Toastify__toast-body {
  font-size: 1rem;
}

@media (max-width: 480px) {
  .chat_overflow_hidden {
    overflow: hidden;
  }
}
