.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 10px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 350px;
}

.logo {
  margin: 24px auto;
}

.button {
  width: 100%;
}
