.absolute {
  position: absolute;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// .animationStripes,
// .animationStripesWhite {
//   position: absolute;
//   top: 0;
//   right: 0;

//   width: 50px;
//   height: 20px;
// }

// .animationStripes {
//   background: -webkit-repeating-linear-gradient(
//     180deg,
//     #b20000 0%,
//     #b20000 14%,
//     #ffffff 14%,
//     #ffffff 28%
//   );
//   background: -o-repeating-linear-gradient(
//     180deg,
//     #b20000 0%,
//     #b20000 14%,
//     #ffffff 14%,
//     #ffffff 28%
//   );
//   background: -moz-repeating-linear-gradient(
//     180deg,
//     #b20000 0%,
//     #b20000 14%,
//     #ffffff 14%,
//     #ffffff 28%
//   );
//   background: repeating-linear-gradient(
//     180deg,
//     #b20000 0%,
//     #b20000 14%,
//     #ffffff 14%,
//     #ffffff 28%
//   );
//   background-size: 150% 100%;
// }

// .stripesWrapper {
//   position: absolute;
//   top: 0;
//   right: 0;
//   overflow: hidden;
//   height: 21px;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }

.stripe {
  animation: progress 0.6s linear infinite alternate;
  // transform-origin: left;
}

@keyframes progress {
  100% {
    transform: scaleX(0.3);
  }
}
