@import "variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "general";

:root {
  --input-disable-color: #{$input-disabled-bg};
  --input-focus-box-shadow: #{$input-focus-box-shadow};
  --input-focus-border-color: #{$input-focus-border-color};
}
//

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 10px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $red;
    border-radius: 4px;
  }
}

body {
  overflow-x: hidden;
  word-break: break-word;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

* {
  box-sizing: border-box;
}

video {
  outline: none;
}

p {
  font-size: 1rem;
}

// Fonts
//@font-face {
//  font-family: 'Avenir Next Cyr Med';
//  src: url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Medium.eot');
//  src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'),
//    url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
//    url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Medium.woff2') format('woff2'),
//    url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Medium.woff') format('woff'),
//    url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Medium.ttf') format('truetype');
//  font-weight: 500;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Regular.eot');
//  src: local('Avenir Next Cyr Regular'), local('AvenirNextCyr-Regular'),
//    url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
//    url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Regular.woff2') format('woff2'),
//    url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Regular.woff') format('woff'),
//    url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Regular.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr Bold';
//  src: url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Bold.eot');
//  src: local('Avenir Next Cyr Bold'), local('AvenirNextCyr-Bold'),
//    url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'),
//    url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Bold.woff2') format('woff2'),
//    url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Bold.woff') format('woff'),
//    url('https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Bold.ttf') format('truetype');
//  font-weight: bold;
//  font-style: normal;
//  font-display: swap;
//}
