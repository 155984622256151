// Main Colors
$red: #b20000;
$red2: #cc0000;
$redDark: #900000;
$redLight: #ff0505;
$blue: #1c1757;
$lightBlue: #2621a3;
$darkCyan: #1e2e4a;
$softDark: #333;
$softGrey: #888;
$softGrey2: #b4b4b4;
$softGrey3: #f3f3f3;
$softGrey4: #dbdbdb;
$softGrey5: #eee;
$linksColor: #337ab7;
$badgeBg: #e5e5e5;
$softDark2: #0f0f0f;
$softWhite: #f1f1f1;
$softWhite2: #fafafa;
$softDark3: #212529;

// Brand colors
$facebookColor: #3a5795;
$twitterColor: #55acee;
$linkedinColor: #007bb6;
$googleColor: #e62117;

// Breakpoints TODO: search wtf & why is xs: 0 ???
$grid-breakpoints: (
  xs: 0,
  xsm: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  exXl: 1300px,
);

// Containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1530px,
);

// Elements
$header-bar-bg: $red;
$welcome-title-bg: $blue;
$footer-copyright-bg: #000;

$right-sidebar-max-width: 380px;
$right-sidebar-min-width: 300px;

$menu-sidebar-min-width: 300px;
$menu-sidebar-max-width: 410px;
$menu-sidebar-base-color: #ffffff;

$menu-header-item-bg: rgba(0, 0, 0, 0.92);
$menu-header-item-transition: opacity 0.3s ease-in-out,
  visibility 0.3s ease-in-out;

$newsItemBorder: 1px solid #eaeaea;

$nav-pills-link-active-bg: $red;

// sidebar block
$sidebar-block-border-color: #e5e5e5;
$sidebar-block-border: 2px solid #e5e5e5;
$sidebar-block-header-action-bg: #eaeaea;
$sidebar-block-header-action-color: $blue;
$sidebar-block-header-action-hover-bg: #111;
$sidebar-block-header-action-hover-color: #fff;
$sidebar-block-preview-bg: #c8c8c8;
$sidebar-block-preview-bg-hover: linear-gradient(
  to left bottom,
  rgba(0, 0, 0, 0) 50%,
  rgba(0, 0, 0, 0.1) 50%
);
$sidebar-block-preview-label-bg: rgb(0, 0, 0, 0.8);
$sidebar-block-indicator-bg: #999;
$sidebar-block-indicator-hover-bg: $red2;
$sidebar-block-indicator-active-bg: $red2;

$sidebar-list-block-border-color: rgba(0, 0, 0, 0.1);
$sidebar-list-block-title-color: #337ab7;
$sidebar-list-block-title-hover-color: $blue;

// News cards
$news-cards-read-more-color: #2621a3;
$news-cards-read-more-hover-color: #23527c;

// Buttons
$btn-red-bg: $red2;
$btn-red-gradient-bg: linear-gradient(
  to left bottom,
  $btn-red-bg 50%,
  #b80000 50%
);
$btn-red-gradient-text: #fff;

$btn-grey-bg: #e6e6e6;
$btn-grey-gradient-bg: linear-gradient(
  to left bottom,
  #ffffff 50%,
  #e6e6e6 50%
);
$btn-black-gradient-bg: linear-gradient(
  to left bottom,
  $softDark 50%,
  #000000 50%
);
$btn-grey-gradient-text: #000;

$btn-play-bg: rgba(0, 0, 0, 0.5);
$btn-play-hover-bg: $red2;
$btn-play-active-bg: $red2;
$btn-play-arrow-bg: rgba(255, 255, 255, 0.5);
$btn-play-arrow-active-bg: #fff;

// Text
$text-color-gray: #333;

// Fonts
$font-family-avnir: "Avenir Next Cyr", sans-serif;
$font-family-avnir-medium: "Avenir Next Cyr Med", sans-serif;
$font-family-avnir-bold: "Avenir Next Cyr Bold", sans-serif;
$font-family-poppins-bold: "Poppins", sans-serif;
$font-family-helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base: $font-family-helvetica;

$h4-font-size: 14px;
$h5-font-size: $h4-font-size;

$font-size-20: 1.25rem;
$font-size-18: 1.125rem;
$font-size-16: 1rem;
$font-size-14: 0.875rem;
$font-size-12: 0.75rem;
$font-size-10: 0.625rem;

// Letter spacing
$text-letter-spacing-tight: 0.02em;
